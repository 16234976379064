a {
  text-decoration: none;
}

body {
  margin: 0 auto;
  box-sizing: border-box;
}

/* Header */

header {
  display: flex;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 999;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
}

.container {
  padding-top: 140px;
}

.logo {
  margin: 40px 10%;
}

.logo img {
  width: 200px;
}

.navigation {
  margin: 40px 5%;
  width: 60%;
}

.navigation ul {
  margin-top: 25px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.navigation li {
  color: black;
}

.navigation li:hover {
  color: red;
  cursor: pointer;
}
/* Home section */
#home {
  padding-top: 140px;
  margin-top: -140px;
}

#home img {
  width: 100%;
}

/* About section */
#about {
  padding-top: 140px;
  margin-top: -140px;
  position: relative;
  z-index: 2;
}

#about h1 {
  margin-top: 50px;
  text-align: center;
  font-size: 50px;
}

#about .aboutImg {
  background-image: url("./img/aboutMobile.jpg");
  width: 80%;
  height: 300px;
  margin: auto 10%;
  background-position: center;
  background-repeat: no-repeat;
}

#about div {
  padding-bottom: 10px;
  background-color: white;
}

#about p {
  margin: 40px 10%;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
/* Services section */
#services {
  width: 100%;
  background: rgba(232, 232, 232, 1);
  padding-bottom: 50px;
  padding-top: 140px;
  margin-top: -140px;
  position: relative;
  z-index: 0;
}

#services h1 {
  text-align: center;
  font-size: 50px;
}

#services div {
  margin: auto 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 23.25%;
  margin-left: -20px;
  padding-bottom: 20px;
}

.card img {
  width: 100%;
}

.maskMock {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

.maskMock img {
  height: 350px;
  margin: 0 10px;
}

.maskMock p {
  text-align: center;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
}

/* Portfolio */
#portfolio {
  padding-top: 140px;
  margin-top: -140px;
  position: relative;
  z-index: 2;
}

#portfolio h1 {
  margin-top: 50px;
  text-align: center;
  font-size: 50px;
}

#portfolio .images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#portfolio .images img {
  height: 300px;
  margin: auto 10px;
}

#portfolio .videos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#portfolio .videos video {
  width: 30%;
  margin: 10px 10px;
}

/* Contact */
#contact {
  padding-top: 140px;
  margin-top: -140px;
}

#contact h1 {
  text-align: center;
  font-size: 50px;
}

.enquire {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.enquire form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -30px;
}

.enquire label {
  margin: 10px auto;
}

.enquire button {
  margin: 10px auto;
}

footer {
  color: white;
  background: black;
  padding-bottom: 10px;
}

footer div {
  padding-top: 10px;
  margin-top: 10px;
  margin-left: 5%;
}

.icons {
  margin-left: 68%;
}

@media (max-width: 750px) {
  header {
    display: block;
    margin-bottom: -10px;
    position: fixed;
    background: white;
    width: 100%;
  }

  .container {
    padding-top: 135px;
  }

  .logo {
    width: 100%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navigation {
    margin: 10px 10%;
    margin-top: -10px;
    width: 80%;
  }

  .navigation ul {
    margin: 0 auto;
    padding: 0 0;
  }

  .navigation li {
    margin: 5px 10px;
  }

  #services div {
    display: block;
    margin: auto 10%;
  }

  .card {
    width: 80%;
  }

  .icons {
    margin-left: 2%;
    margin-right: 5px;
  }

  #about .aboutImg {
    background-image: url("./img/aboutMobile.jpg");
    width: 80%;
    height: 300px;
    margin: auto 10%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .maskMock img {
    width: 100%;
    height: auto;
  }

  #portfolio .images img {
    width: 80%;
    height: auto;
    margin: 10px auto;
  }

  #portfolio .videos video {
    width: 80%;
    height: auto;
    margin: 10px auto;
  }
}
